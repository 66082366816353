import useSWR from "swr";

export function useInstrumentData(pair, timeframe) {
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_API_URL}/instrument/${pair}/timeframe/${timeframe}`
  );

  return {
    instrumentData: data,
    isLoading,
    isError: error,
  };
}
