export function calculateScore(actions, limit) {
  const scoreMapping = {
    sell: -1,
    hold_sell: -0.5,
    hold: 0,
    hold_buy: 0.5,
    buy: 1,
  };

  const score = actions.reduce(
    (total, action) => total + (scoreMapping[action] || 0),
    0
  );

  const minScore = -limit;
  const maxScore = limit;

  let normalizedScore;
  if (score <= minScore) {
    normalizedScore = 0;
  } else if (score >= maxScore) {
    normalizedScore = 1;
  } else {
    normalizedScore = (score - minScore) / (maxScore - minScore);
  }

  return normalizedScore;
}
