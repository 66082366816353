import "./App.css";

import { useTrades } from "./api/trades";
import Grid from "@mui/material/Unstable_Grid2";
import Spinner from "react-bootstrap/Spinner";
// import { usePairs } from "./api/pairs";
// import { useTimeframes } from "./api/timeframes";
import { Instrument } from "./App";
import { useInstrumentData } from "./api/instrumentData";
// import "./widget";

function Pairs() {
  const pairs = [
    "EUR_USD",
    "GBP_USD",
    "USD_CHF",
    "AUD_USD",
    "USD_CAD",
    "NZD_USD",
    "EUR_CAD",
    "EUR_CHF",
    "GBP_CAD",
    "NZD_CAD",
    "EUR_NZD",
    // "CAD_SGD",
    "EUR_GBP",
    // "SGD_CHF",
    "AUD_CHF",
  ].sort();

  return (
    <Grid container>
      {pairs.map((p) => (
        <InstrumentPrediction pair={p} key={p} />
      ))}
    </Grid>
  );
}

// function Pair({ pair }) {
//   return <PairPredict pair={pair} />;
// }

// function PairPredict({ pair }) {
//   const { isLoading, timeframes, isError } = useTimeframes();

//   if (isLoading) {
//     return <Spinner animation="border" variant="primary" />;
//   }

//   if (isError) {
//     return <div>Error</div>;
//   }

//   return <InstrumentPrediction pair={pair} timeframes={timeframes} />;
// }

function InstrumentPrediction({ pair }) {
  const {
    isLoading: isLoadingTrades,
    trades,
    isError: isErrorTrades,
  } = useTrades();

  const {
    isLoading: instrumentDataM15IsLoading,
    instrumentData: instrumentDataM15,
    error: instrumentDataM15IsError,
  } = useInstrumentData(pair, "M15");

  const {
    isLoading: instrumentDataM30IsLoading,
    instrumentData: instrumentDataM30,
    error: instrumentDataM30IsError,
  } = useInstrumentData(pair, "M30");

  const {
    isLoading: instrumentDataH1IsLoading,
    instrumentData: instrumentDataH1,
    error: instrumentDataH1IsError,
  } = useInstrumentData(pair, "H1");

  const {
    isLoading: instrumentDataH2IsLoading,
    instrumentData: instrumentDataH2,
    error: instrumentDataH2IsError,
  } = useInstrumentData(pair, "H2");

  const {
    isLoading: instrumentDataH4IsLoading,
    instrumentData: instrumentDataH4,
    error: instrumentDataH4IsError,
  } = useInstrumentData(pair, "H4");

  const {
    isLoading: instrumentDataH8IsLoading,
    instrumentData: instrumentDataH8,
    error: instrumentDataH8IsError,
  } = useInstrumentData(pair, "H8");

  const {
    isLoading: instrumentDataDIsLoading,
    instrumentData: instrumentDataD,
    error: instrumentDataDIsError,
  } = useInstrumentData(pair, "D");

  // const {
  //   isLoading: instrumentDataM1IsLoading,
  //   instrumentData: instrumentDataM1,
  //   error: instrumentDataM1IsError,
  // } = useInstrumentData(pair, "M1");

  if (
    isLoadingTrades ||
    instrumentDataM15IsLoading ||
    instrumentDataM30IsLoading ||
    instrumentDataH1IsLoading ||
    instrumentDataH2IsLoading ||
    instrumentDataH4IsLoading ||
    instrumentDataH8IsLoading ||
    instrumentDataDIsLoading
    // || instrumentDataM1IsLoading
  ) {
    return (
      <div>
        <Spinner animation="border" />
      </div>
    );
  }

  if (
    isErrorTrades ||
    instrumentDataM30IsError ||
    instrumentDataM15IsError ||
    instrumentDataH1IsError ||
    instrumentDataH2IsError ||
    instrumentDataH4IsError ||
    instrumentDataH8IsError ||
    instrumentDataDIsError
    // || instrumentDataM1IsError
  ) {
    return <div>Error</div>;
  }

  // console.log(predictM15);
  // console.log(predictM30);

  // console.log(isLoadingM15);
  return (
    <>
      <Instrument
        pair={pair}
        instrumentDataM30={instrumentDataM30}
        instrumentDataM15={instrumentDataM15}
        instrumentDataH1={instrumentDataH1}
        instrumentDataH2={instrumentDataH2}
        instrumentDataH4={instrumentDataH4}
        instrumentDataH8={instrumentDataH8}
        instrumentDataD={instrumentDataD}
        // instrumentDataM1={instrumentDataM1}
        // score={[...predictM15.predictions, ...predictM30.predictions]}
        trades={trades}
      />
    </>
  );
}

// function InstrumentTimeframeData({ pair, timeframe }) {
//   const { isLoading, predict, isError } = usePredict(pair, timeframe);

//   if (isLoading) {
//     return <Spinner animation="border" variant="primary" />;
//   }

//   if (isError) {
//     return <div>Error</div>;
//   }

//   return (
//     <div>
//       <div>
//         <div>{pair}</div>
//         <div>{timeframe}</div>
//         <div>{JSON.stringify(predict)}</div>{" "}
//       </div>
//     </div>
//   );
// }

// function Prediction({ pair, timeframe }) {
//   const { isLoading, predict, isError } = usePredict(pair, timeframe);

//   if (isLoading) {
//     return <Spinner animation="border" variant="primary" />;
//   }

//   if (isError) {
//     return <div>Error</div>;
//   }

//   return (
//     <div>
//       <div>
//         <div>{pair}</div>
//         <div>{timeframe}</div>
//         <div>{JSON.stringify(predict)}</div>{" "}
//       </div>
//     </div>
//   );
// }

// function Prediction({ pair, timeframe, data }) {
//   const { isLoading, predict, isError } = usePredict(pair, timeframe, data);

//   if (isLoading) {
//     return <Spinner animation="border" variant="primary" />;
//   }

//   if (isError) {
//     return <div>Error</div>;
//   }

//   return (
//     <div>
// <div>{pair}</div>
// <div>{timeframe}</div>

// <div>{JSON.stringify(predict)}</div>
//     </div>
//   );
// }

// function Pair({pair}) {
//   const { isLoading, timeframes, isError } = useTimeframes();

//   if (isLoading) {
//     return <Spinner animation="border" variant="primary" />;
//   }

//   if (isError) {
//     return <div>Error</div>;
//   }

//   return <div>{JSON.stringify(pairs)}</div>;
// }

export default Pairs;
