import useSWR from "swr";

export function useTrades() {
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_API_URL}/trades`
  );

  return {
    trades: data,
    isLoading,
    isError: error,
  };
}
